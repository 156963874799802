$orange: #F47920;
$brown: #533019;
$text-blue: #7080a2;
$lightblue: #36afda;
$text-gray: #666;
$footerblue: #97b0c5;

.navbar-brand {
    position: absolute;
    
    img {
        max-height: 61px;
    }
}

.navbar-toggler-right {
    position: relative;
}

.agency-contact-form {
    form {
        margin: 0 auto;
    
        input[type=email], input[type=text], textarea, input[type=submit] {
            padding: 10px 20px;
            font-size: 16px;
        }

        input[type=submit] {
            background: darken($orange, 10%);
            &:hover {
                background: $orange;
            }
        }
    }
    
    h2 {
        font-size: 26px;
        letter-spacing: .1px;
        font-weight: 400;
    }
} 

.footer-big-menu {
    margin-top: 0px;
}

.agency-contact-map {
    margin-top:0px;
}

.agency-contact-form 
{
    h2 {
        color: $orange;
    }
}

.custom-section {
    margin-top: 120px;
    background: #fafafa;
    border-top: 1px solid #e2e1e1;
    border-bottom: 1px solid #e2e1e1;
    padding: 70px 0 70px;
}


.business-hero {
    h2 {
        width: auto;
    }

    strong {
        color: $orange;
    }

    .btn-shadow-primary {
        background: darken($orange, 10%);
        border: 1px solid darken($orange, 10%);

        &:hover {
            background: $orange;
            border: 1px solid $orange;
        }
    }

}

.spacial-features {
    .header {
        h2 {
            color: $orange;
        }
    }
    .feature {
        h3 {
            /*color: $orange;*/
        }

        .icon i {
            color: $text-gray;
        }
    }
}

.navbar-inverse .nav-item .nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
}

.navbar-inverse .nav-item .nav-link:hover {
    color: #fff;
}

 .navbar-inverse .navbar-nav .active > .nav-link {
    color: #fff;
 }

.index-features {
    header {
        h3 {
            color: $orange;
        }
    } 
}

 .index-devices {
    padding-top: 70px;
    overflow: hidden;

    p {
        width: auto;
    }
    
    h4 {
        color: $orange;
    }

    .devices {
        width: 570px;
        left: 50px;
    }
 }

.business-single-client {
    margin-top: 70px;
 }


.footer-big-menu {
    padding: 70px 0;
    p {
        color: $footerblue;
    }

    .menu {
        margin-top: 50px;
    }

    .bottom {
        a {
            margin-left: 0;
        }
    }
}

.btn-shadow.btn-shadow-info {
    background: darken($orange, 10%);
    &:hover {
        background: $orange;
    }
}