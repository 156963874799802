.agency-about-hero {
  height: 550px;
  position: relative;
  background-image: url('../images/unsplash/photo-1422558041234-896cd9791234.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;

  @media(max-width: 1024px) {
    background-attachment: inherit;
  }

  @media(max-width: 767px) {
    height: 500px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(bottom left, rgba(28, 46, 79, 0.62) 0%, rgba(28, 46, 79, 0.7) 14.87%, rgba(45, 83, 123, 0.6) 100%);
    background: linear-gradient(to bottom left, rgba(28, 46, 79, 0.62) 0%, rgba(28, 46, 79, 0.7) 14.87%, rgba(45, 83, 123, 0.6) 100%);
  }

  section {
    position: relative;
    z-index: 9;
    height: 100%;
  }

  .hero-text {
    position: absolute;
    left: 0;
    right: 0;
    top: 240px;

    @media(max-width: 767px) {
      top: 170px;
    }
  }

  p {
    color: #fff;
    text-align: center;
    font-size: 24px;
    line-height: 37px;
    width: 70%;
    margin: 0 auto;

    @include animationDelay(.3s);

    @media(max-width: 767px) {
      font-size: 21px;
      width: 80%;
    }
  }
}

.agency-about-intro {
  margin-top: 90px;

  p {
    text-align: center;
    font-size: 17px;
    width: 65%;
    margin: auto;
    line-height: 32px;
    color: #828A98;

    @media(max-width: 767px) {
      width: auto;
    }
  }
}

.agency-about-team {
  margin-top: 100px;
  background: #F9F9F9;
  padding-top: 50px;
  padding-bottom: 70px;
  border-top: 1px solid #F0F0F0;

  h4 {
    text-align: center;
    font-size: 19px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  hr {
    width: 50px;
    margin: 20px auto 25px;
    border-top: 2px solid #ccc;
  }

  h3 {
    margin-bottom: 70px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #444;
  }

  .member {
    text-align: center;
    margin: 0 auto;
    width: 128px;
    height: 128px;
    position: relative;

    &:hover {
      .mask {
        opacity: 1;
      }
    }

    .mask {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 50%;
      background: rgba(29, 32, 34, 0.7);
      opacity: 0;

      @include transition(all .4s);

      a {
        font-size: 18px;
        text-align: center;
        margin: 0 3px;
        position: relative;
        top: 52px;
        width: 25px;
        height: 25px;
        display: inline-block;
        background: #fff;
        border-radius: 50%;
        color: rgba(56, 64, 74, 1);
      }
    }

    img {
      border-radius: 50%;
    }
  }

  .name {
    text-align: center;
    margin-top: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 13px;
    margin-bottom: 45px;
    font-weight: 500;
  }
}

@include keyFrame(collage) {
  0% {
    @include transform(translate3d(0, 0, 0));
  }
  100% {
    @include transform(translate3d(-1810px, 0, 0));
  }
}

.agency-about-pics {
  position: relative;
  height: 450px;
  width: 100%;
  max-width: inherit;
  overflow: hidden;

  #photos {
    position: absolute;
    height: 100%;
    width: 5430px;
    background: transparent url('../images/graphics/collage.jpg') repeat-x left top;
    
    @include transform(translate3d(0, 0, 0));
    @include animation(collage 60s linear infinite);
  }
}

.agency-about-cta {
  margin-top: 70px;
  text-align: center;

  h3 {
    font-size: 28px;
    color: #4a5969;

    @media(max-width: 767px) {
      font-size: 25px;
    }
  }

  p {
    font-size: 16px;
    color: #7B899E;
    margin-top: 18px;
    line-height: 28px;
  }
}

.agency-about-clients {
  margin-top: 140px;
  margin-bottom: -60px;
  text-align: center;

  header {
    margin-bottom: 30px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      background: #E9E9E9;
      top: 11px;
    }

    h4 {
      position: relative;
      padding: 0 40px;
      background: #fff;
      color: #97A8B8;
      font-family: $lato;
      display: inline-block;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 1.8px;
    }
  }
}
