.blog-grid-slider {
  .slider-component {
    height: 400px;

    @media(max-width: 767px) {
      height: 360px;
    }

    .pic {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(rgba(34, 34, 45, 0.25) 10%, rgba(32, 45, 80, 0.65) 100%);
      }
    }

    .post {
      position: relative;
      top: 250px;

      @media(max-width: 991px) {
        text-align: center;
      }

      @media(max-width: 767px) {
        top: 220px;
      }

      h1 {
        color: #fff;
        font-size: 33px;
        font-weight: 400;
        margin-top: 20px;

        @media(max-width: 767px) {
          font-size: 25px;
        }

        a {
          color: #fff;
          text-decoration: none;
        }
      }

      .bottom {
        margin-top: 15px;
      }

      .avatar {
        width: 50px;
        border-radius: 70%;
        position: relative;
        top: -3px;
        margin-right: 15px;
      }

      .category {
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 1px;
        color: #13C5E5;
        font-size: 12px;
      }

      .date {
        color: #dadada;
        margin-left: 30px;
      }
    }
  }
}

.blog-posts-grid {
  margin-top:50px;

  &--keep-reading {
    margin-top: 50px;
    padding: 40px 0 100px;
    background: #fbfbfb;
    margin-bottom: -100px;

    h3 {
      text-align: center;
      font-size: 20px;
      margin-bottom: 50px;
    }
  }

  .post {
    margin: 2px;
    position: relative;
    display: inline-block;
    height: 280px;
    width: calc(33.333% - 8px);
    overflow: hidden;

    @media(max-width: 767px) {
      width: 100% !important;
      margin: 0px !important;
    }

    &:first-child {
      width: calc(66.666% - 9px);
    }

    &:nth-child(8n-2) {
      width: calc(100% - 10px);
      margin: 0 2px;
    }

    &:nth-child(8n-1) {
      width: calc(66.666% - 10px);
      height: 400px;
    }

    &:nth-child(8) {
      height: 400px;
    }

    &:hover {
      .bg {
        @include transform(scale3d(1, 1, 1));
      }

      .info .author {
        opacity: 1;
      }

      .info .date {
        opacity: 1;
      }
    }

    .bg {
      position: absolute;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;

      @include transition(all .15s linear);
      @include transform(scale3d(1.15, 1.15, 1.15));

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;

        background: linear-gradient(rgba(34, 34, 45, 0.2) 10%, rgba(32, 45, 80, 0.6) 100%);
      }
    }

    .info {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 9;
      text-decoration: none;
      padding: 40px;

      .title {
        font-size: 26px;
        color: #fff;
        display: block;
        position: absolute;
        font-weight: 400;
        width: 70%;

        @media(max-width: 767px) {
          font-size: 20px;
        }
      }

      .author {
        color: #fff;
        display: block;
        position: absolute;
        font-size: 13px;
        bottom: 20px;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.5px;
        opacity: 0;

        @include transition(all .2s linear);
      }

      .date {
        color: #fff;
        display: block;
        font-size: 13px;
        position: absolute;
        bottom: 20px;
        right: 30px;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.5px;
        opacity: 0;

        @include transition(all .2s linear);
      }
    }
  }
}
